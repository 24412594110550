import "./Header.css";
import history from "../history";
import React from "react";
import { Link } from "react-router-dom";

class Header extends React.Component {
	signOut = () => {
		localStorage.removeItem("spotify_access_token");
		localStorage.removeItem("spotify_token_expires");
		history.push("/");
		window.location.reload();
	};

	render() {
		return (
			<div className="ui masthead center aligned segment">
				<div className="ui text container">
					<h1 className="ui header">
						<Link to="/genres" className="item">
							Name That Tune
        	  </Link>
					</h1>
					<img src="/girl.svg" alt="Girl listening to music" />
				</div>

				
				<div className="left corner">
					<div className="button" onClick={() => window.history.back()}>
						Back
          </div>
				</div>

				
				<div className="right corner">
					<div className="button" onClick={() => this.signOut()}>
						Sign Out
          </div>
				</div>
			</div>
		);
	}
}

export default Header;
