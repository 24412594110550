import { combineReducers } from "redux";
import genreReducer from "./genreReducer";
import playlistsReducer from "./playlistsReducer";
import playlistReducer from "./playlistReducer";
import appSettingsReducer from "./appSettingsReducer";

export default combineReducers({
  genres: genreReducer,
  genre: playlistsReducer,
  playlist: playlistReducer,
  appSettings: appSettingsReducer,
});
