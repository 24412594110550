import "./PrimaryLayout.css";
import React from "react";
import { Route } from "react-router-dom";
import Header from "../components/Header";
import Genres from "../components/Genres";
import Genre from "../components/Genre";
import Playlist from "../components/Playlist";

const PrimaryLayout = () => (
  <div>
    <Header />
    <div className="ui container">
      <Route path="/genres" exact component={Genres} />
      <Route path="/genres/:id" exact component={Genre} />
      <Route path="/playlists/:id" exact component={Playlist} />
    </div>
  </div>
);

export default PrimaryLayout;
