import "./Genres.css";
import _ from "lodash";

import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { fetchGenres } from "../actions";

class Genres extends React.Component {
  componentDidMount() {
    this.props.fetchGenres();
  }

  renderList() {
    if (_.isEmpty(this.props.genres)) return;

    return this.props.genres.items.map((genre) => {
      return (
        <Link to={`/genres/${genre.id}`} className="card" key={genre.id}>
          <div className="image">
            <img src={genre.icons[0].url} alt={genre.name} />
            <div className="content">{genre.name}</div>
          </div>
        </Link>
      );
    });
  }

  render() {
    return (
      <div>
        <h2 className="pageHeader">Choose a Genre</h2>
        <div className="ui six stackable cards">{this.renderList()}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    genres: state.genres,
  };
};

export default connect(mapStateToProps, { fetchGenres })(Genres);
