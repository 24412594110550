import "./Playlist.css";
import _ from "lodash";

import React from "react";
import { connect } from "react-redux";
import {
  fetchPlaylist,
  playTrack,
  pauseTrack,
  revealTrack,
  selectTrack,
  fetchAppSettings,
  updateAppSettings,
} from "../actions";

let timer = null;

class Playlist extends React.Component {
  componentDidMount() {
    this.props.fetchPlaylist(this.props.match.params.id);
    this.props.fetchAppSettings();
  }

  playTrack = (track) => {
    this.props.playTrack(track.id).then(this.startTimer(track));
  };

  selectDuration = (duration) => {
    if (duration) {
      this.props.appSettings.songDuration = duration;
      this.updateDifficultyLevel(duration);
    }
  };

  startTimer(track) {
    timer = setTimeout(
      () => this.stopTimer(track),
      this.props.appSettings.songDuration
    );
  }

  stopTimer(track) {
    this.props.pauseTrack(track.id);
    clearTimeout(timer);
  }

  revealTrack = (track) => {
    this.props.revealTrack(track.id);
  };

  selectTrack = (track) => {
    this.props.selectTrack(track.id);
  };

  updateDifficultyLevel = (value) => {
    this.props.updateAppSettings("songDuration", parseInt(value, 10));
  };

  renderList() {
    if (_.isEmpty(this.props.playlist)) return;

    return this.props.playlist.tracks.items.map((track, index) => {
      return (
        <div
          className="item"
          style={{ display: "flex", alignItems: "center" }}
          key={track.track.id}
        >
          <span className="trackIndex">{`${index + 1}.`}</span>

          {track.track.reveal ? (
            <div className="itemContainer revealedTrackContainer">
              <div className="albumArt">
                <img
                  src={track.track.album.images[2].url}
                  alt={track.track.album.name}
                />
              </div>
              <div className="artist">{track.track.artists[0].name}</div>
              <div className="songTitle">{track.track.name}</div>
              <div className="songYear">
                {new Date(track.track.album.release_date).getFullYear()}
              </div>
            </div>
          ) : null}

          {track.track.selected && !track.track.reveal ? (
            <div className="itemContainer selectedTrackContainer">
              {track.track.playing ? (
                <div id="bars">
                  <div className="bar"></div>
                  <div className="bar"></div>
                  <div className="bar"></div>
                  <div className="bar"></div>
                  <div className="bar"></div>
                  <div className="bar"></div>
                  <div className="bar"></div>
                  <div className="bar"></div>
                  <div className="bar"></div>
                  <div className="bar"></div>
                  <div className="bar"></div>
                  <div className="bar"></div>
                </div>
              ) : (
                <button
                  className="ui icon button bigPlayTrackButton"
                  onClick={() => this.playTrack(track.track)}
                >
                  <i className="huge play icon"></i>
                  <div>SPACEBAR</div>
                </button>
              )}
              <div className="buttons">
                <button
                  className={`ui inverted basic button ${
                    this.props.appSettings.songDuration === 1500
                      ? "selected"
                      : ""
                  }`}
                  onClick={() => this.selectDuration(1500)}
                >
                  <div>
                    Hard
                    <br />1 sec
                  </div>
                </button>
                <button
                  className={`ui inverted basic button ${
                    this.props.appSettings.songDuration === 5000
                      ? "selected"
                      : ""
                  }`}
                  onClick={() => this.selectDuration(5000)}
                >
                  <div>
                    Medium
                    <br />5 sec
                  </div>
                </button>
                <button
                  className={`ui inverted basic button ${
                    this.props.appSettings.songDuration === 15000
                      ? "selected"
                      : ""
                  }`}
                  onClick={() => this.selectDuration(15000)}
                >
                  <div>
                    Easy
                    <br />
                    15 sec
                  </div>
                </button>
              </div>
              <button
                className="ui button revealButton"
                onClick={() => this.revealTrack(track.track)}
              >
                Reveal
              </button>
            </div>
          ) : null}

          {!track.track.selected && !track.track.reveal ? (
            <div className="itemContainer placeHolderContainer">
              <div className="albumArt">
                <button
                  className="ui icon button"
                  onClick={() => this.selectTrack(track.track)}
                >
                  <i className="play icon"></i>
                </button>
              </div>
              <div className="artist">
                <div className="placeHolderBar"></div>
              </div>
              <div className="songTitle">
                <div className="placeHolderBar"></div>
              </div>
              <div className="songYear">
                <div className="placeHolderBar"></div>
              </div>
            </div>
          ) : null}
        </div>
      );
    });
  }

  render() {
    if (!this.props.playlist) {
      return <div>Loading...</div>;
    }

    return (
      <div>
        <h2 className="pageHeader">{this.props.playlist.name}</h2>
        <h3 className="pageSubHeader">Choose a song to start playing</h3>
        <div className="ui middle aligned divided list">
          {this.renderList()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    playlist: state.playlist,
    appSettings: state.appSettings,
  };
};

export default connect(mapStateToProps, {
  fetchPlaylist,
  playTrack,
  pauseTrack,
  revealTrack,
  selectTrack,
  fetchAppSettings,
  updateAppSettings,
})(Playlist);
