import spotifyApi from "../api/spotify";
import {
  FETCH_GENRES,
  FETCH_GENRE,
  FETCH_PLAYLIST,
  PLAY_TRACK,
  PAUSE_TRACK,
  REVEAL_TRACK,
  SELECT_TRACK,
  FETCH_APP_SETTINGS,
  UPDATE_APP_SETTINGS,
} from "./types";

export const fetchGenres = () => async (dispatch) => {
  try {
    const response = await spotifyApi.get(
      "/browse/categories?country=CA&limit=30"
    );

    dispatch({
      type: FETCH_GENRES,
      payload: response.data,
    });
  } catch (err) {
    console.error(err);
  }
};

export const fetchGenre = (id) => async (dispatch) => {
  try {
    const response = await spotifyApi.get(
      `/browse/categories/${id}/playlists?country=CA&limit=30`
    );

    dispatch({
      type: FETCH_GENRE,
      payload: response.data,
    });
  } catch (err) {
    console.error(err);
  }
};

export const fetchPlaylist = (id) => async (dispatch) => {
  try {
    const response = await spotifyApi.get(`/playlists/${id}`);

    dispatch({
      type: FETCH_PLAYLIST,
      payload: response.data,
    });
  } catch (err) {
    console.error(err);
  }
};

export const playTrack = (id) => async (dispatch) => {
  try {
    await spotifyApi.put(`/me/player/play`, {
      uris: [`spotify:track:${id}`],
      position_ms: 20000,
    });

    dispatch({
      type: PLAY_TRACK,
      payload: { id },
    });
  } catch (err) {
    console.error(err);
  }
};

export const pauseTrack = (id) => async (dispatch) => {
  try {
    await spotifyApi.put(`/me/player/pause`, {});

    dispatch({
      type: PAUSE_TRACK,
      payload: { id },
    });
  } catch (err) {
    console.error(err);
  }
};

export const revealTrack = (id) => async (dispatch) => {
  dispatch({
    type: REVEAL_TRACK,
    payload: { id },
  });
};

export const selectTrack = (id) => async (dispatch) => {
  dispatch({
    type: SELECT_TRACK,
    payload: { id },
  });
};

export const fetchAppSettings = () => async (dispatch) => {
  dispatch({
    type: FETCH_APP_SETTINGS,
    payload: {},
  });
};

export const updateAppSettings = (name, value) => async (dispatch) => {
  dispatch({
    type: UPDATE_APP_SETTINGS,
    payload: { name, value },
  });
};
