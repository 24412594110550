import { FETCH_APP_SETTINGS, UPDATE_APP_SETTINGS } from "../actions/types";

export default (state = { songDuration: 1500 }, action) => {
  switch (action.type) {
    case FETCH_APP_SETTINGS:
      return state;
    case UPDATE_APP_SETTINGS:
      return { ...state, [action.payload.name]: action.payload.value };
    default:
      return state;
  }
};
