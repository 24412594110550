import "./Splash.css";

import history from "../history";
import React from "react";
import SpotifyLogin from "react-spotify-login";
import { CLIENT_ID, REDIRECT_URI, SPOTIFY_SCOPE } from "../settings";

class Splash extends React.Component {
  state = {
    isSignedIn: null,
    expiresIn: 0,
  };

  componentDidMount() {
    if (localStorage.getItem("spotify_access_token"))
      this.setState({
        isSignedIn: localStorage.getItem("spotify_access_token"),
      });

    if (localStorage.getItem("spotify_token_expires")) {
      localStorage.getItem("spotify_token_expires") >= Date.now()
        ? this.setState({
            expiresIn: localStorage.getItem("spotify_token_expires"),
          })
        : this.signOut();
    }

    if (this.state.isSignedIn) window.location.href = "/genres";
  }

  signOut = () => {
    localStorage.removeItem("spotify_access_token");
    localStorage.removeItem("spotify_token_expires");
    history.push("/");
    window.location.reload();
  };

  signin = () => {
    window.location.href = "/genres";
  };

  renderAuthButton() {
    const onSuccess = (response) => {
      if (response.access_token) {
        localStorage.setItem("spotify_access_token", response.access_token);
        this.setState({ isSignedIn: response.access_token });
      }

      if (response.expires_in) {
        let expiresIn = Date.now() + parseInt(response.expires_in, 10);
        localStorage.setItem("spotify_token_expires", expiresIn);
        this.setState({ expiresIn });
      }
      this.signin();
    };
    const onFailure = (response) => console.error(response);

    return (
      <SpotifyLogin
        clientId={CLIENT_ID}
        scope={SPOTIFY_SCOPE}
        redirectUri={REDIRECT_URI}
        onSuccess={onSuccess}
        onFailure={onFailure}
        className="ui primary button login-button"
      />
    );
  }

  render() {
    return (
      <div className="splash">
        <h2 className="heading">Name That Tune</h2>
        <p>To play, you'll need to</p>
        <div className="">{this.renderAuthButton()}</div>
      </div>
    );
  }
}

export default Splash;
