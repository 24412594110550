import React from "react";
import { Switch, Router, Route, Redirect } from "react-router-dom";
import Splash from "./Splash";
import PrimaryLayout from "../layouts/PrimaryLayout";
import history from "../history";

const App = () => {
  return (
      <Router history={history}>
        <Switch>
          <Route path="/" exact component={Splash} />
          <PrimaryLayout />
          <Redirect to="/" />
        </Switch>
      </Router>
  );
};

export default App;
