import _ from "lodash";
import {
  FETCH_PLAYLIST,
  PLAY_TRACK,
  PAUSE_TRACK,
  REVEAL_TRACK,
  SELECT_TRACK,
} from "../actions/types";

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_PLAYLIST:
      return action.payload;
    case PLAY_TRACK:
      let selectedTrack = _.find(state.tracks.items, (track) => {
        return track.track.id === action.payload.id;
      });

      if (selectedTrack) selectedTrack.track.playing = true;

      return { ...state };
    case PAUSE_TRACK:
      let selectedTrack2 = _.find(state.tracks.items, (track) => {
        return track.track.id === action.payload.id;
      });

      if (selectedTrack2) selectedTrack2.track.playing = false;

      return { ...state };

    case REVEAL_TRACK:
      let selectedTrack3 = _.find(state.tracks.items, (track) => {
        return track.track.id === action.payload.id;
      });

      if (selectedTrack3) selectedTrack3.track.reveal = true;

      return { ...state };

    case SELECT_TRACK:
      state.tracks.items = _.map(state.tracks.items, (track) => {
        track.track.selected = false;
        return track;
      });

      let selectedTrack4 = _.find(state.tracks.items, (track) => {
        return track.track.id === action.payload.id;
      });

      if (selectedTrack4) selectedTrack4.track.selected = true;

      return { ...state };
    default:
      return state;
  }
};
