import _ from "lodash";

import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { fetchGenre } from "../actions";

class Genre extends React.Component {
	componentDidMount() {
		this.props.fetchGenre(this.props.match.params.id);
	}

	renderList() {
		if (_.isEmpty(this.props.genre)) return;

		return this.props.genre.items.map((playlist) => {
			return (
				<Link to={`/playlists/${playlist.id}`} className="card" key={playlist.id}>
					<div className="image">
						<img src={playlist.images[0].url} alt={playlist.name} />
					</div>
					{/* <Link to={`/playlists/${playlist.id}`} className="content">
						{playlist.name}
					</Link> */}
				</Link>
			);
		});
	}

	render() {
		return (
			<div>
				<h2 className="pageHeader">Choose a Playlist</h2>
				<div className="ui six stackable cards">{this.renderList()}</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		genre: state.genre,
	};
};

export default connect(mapStateToProps, { fetchGenre })(Genre);
