import axios from "axios";
import history from "../history";

let token = null;
if (localStorage.getItem("spotify_access_token"))
  token = localStorage.getItem("spotify_access_token");

const spotifyAxios = axios.create({
  baseURL: "https://api.spotify.com/v1",
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

spotifyAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("spotify_access_token");
      localStorage.removeItem("spotify_token_expires");
      history.push("/");
      window.location.reload();
    }
    return error;
  }
);

export default spotifyAxios;
